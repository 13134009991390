

























































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import authStore from "@/store/modules/auth";
import commonstore from "@/store/modules/common";
import { IncidentRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    Input
  }
})

export default class Login extends Vue {
  
  get user() {
    return authStore.user;
  }
  async submit(e: any) {
    commonstore.showLoading();
    await authStore.registerUser();
    this.$snotify.success("saved successfully");
    commonstore.hideLoading();
    this.$router.push(IncidentRouter.IncidentDashboard);
  }
}
